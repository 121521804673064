@import url('https://fonts.googleapis.com/css2?family=Rozha+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rozha+One&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&family=Rozha+One&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

BODY {
    background-color: black;
    font-family: sans-serif;
    font-size: 1.05vw;
    /* color: #e2e2e2; */
    color: #d4d4d4;
}
p {
    padding: 0;
    margin: 0;
}
.sm {
    display: none;
}

.container {
    background-color: black;
    width: 99vw;
    height: 97vh;
    padding: 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.outer {
    position: relative;
    height: 90%;
    width: 90%;
    align-items: center;
    justify-content: center;
}

.inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    min-height: 100%;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    align-items: stretch;
    background-color: #282828;
}

.item {
    /* background-color: #282828; */
    width: calc((100% - 2px) / 3);
    /* height: calc((100% - 2px) / 3); */
    /* min-height: calc((100% - 2px) / 3); */
    height: calc((100% - 2px) / 3);
    max-height: calc((100% - 2px) / 3);
    display: flex;
}

.column-2 {
    flex: 2 0 calc(((100% - 2px) / 3) * 2);
    /* Double width */
}

.column-3 {
    flex: 3 1 auto;
}

.left {
    justify-content: start;

}

.center {
    justify-content: center;

}

.right {
    justify-content: end;

}

.top {
    align-items: start;
}

.middle {
    align-items: center;
}

.bottom {
    align-items: end;
}

.img {
    height: calc(100% - 1vw);
    margin: .5vw;
    width: auto;
}

.borderTLH {
    position: absolute;
    background-color: #fff;
    top: 0;
    left: 0;
    width: 5%;
    height: 2px;
}

.borderTLV {
    position: absolute;
    background-color: #fff;
    top: 0;
    left: 0;
    width: 2px;
    height: 10%;
}

.borderTRH {
    position: absolute;
    background-color: #fff;
    top: 0;
    right: 0;
    width: 5%;
    height: 2px;
}

.borderTRV {
    position: absolute;
    background-color: #fff;
    top: 0;
    right: 0;
    width: 2px;
    height: 10%;
}

.borderBRH {
    position: absolute;
    background-color: #fff;
    bottom: 0;
    right: 0;
    width: 5%;
    height: 2px;
}

.borderBRV {
    position: absolute;
    background-color: #fff;
    bottom: 0;
    right: 0;
    width: 2px;
    height: 10%;
}

.borderBLH {
    position: absolute;
    background-color: #fff;
    bottom: 0;
    left: 0;
    width: 5%;
    height: 2px;
}

.borderBLV {
    position: absolute;
    background-color: #fff;
    bottom: 0;
    left: 0;
    width: 2px;
    height: 10%;
}

.hr-vertical {
    /* height: calc((60% - 2px) / 3); */
    /* min-height: calc((60% - 2px) / 3); */
    width: 1px;
    max-width: 1px;
    min-width: 1px;
    background-image: url("data:image/svg+xml,%3csvg width='1px' height='20px' xmlns='http://www.w3.org/2000/svg'%3e%3cline x1='0.5' y1='5' x2='0.5' y2='15' stroke='white' stroke-width='1' stroke-dasharray='10%2c 10'/%3e%3c/svg%3e");
    background-repeat: repeat-y;
    background-position-y: center;
}

.hr-vertical-top {
    /* margin-top: 75px; */
    /* height: 50% !important; */
    position: relative;
    /* position: fixed; */
    top: 7.5%;

    /* left: calc((100% - 2px) / 3); */
    /* right: calc((100% - 2px) / 3); */
}

.hr-vertical-top-small {
    position: relative;
    top: 2.5% !important;
}

.hr-vertical-middle {
    /* margin-top: 37.5px; */
    /* margin-bottom: 37.5px; */
    visibility: hidden;
}

.hr-vertical-bottom {
    /* margin-top: 10px; */
    /* visibility: hidden; */
    position: relative;
    top: -7.5%;
}

.hr-vertical-bottom-small {
    position: relative;
    top: -2.5% !important;
}

.hr-horizontal {
    height: 1px;
    max-height: 1px;
    min-height: 1px;
    width: 100%;
    min-width: 100%;
    background-image: url("data:image/svg+xml,%3csvg width='20px' height='1px' xmlns='http://www.w3.org/2000/svg'%3e%3cline x1='5' y1='0.5' x2='15' y2='0.5' stroke='white' stroke-width='1' stroke-dasharray='10%2c 10'/%3e%3c/svg%3e");
    background-repeat: repeat-x;
    background-position-x: center;
}

h1 {
    font-size: 3.15vw;
    color: #fff;
}

h2 {
    font-size: 2vw;
}

h3 {
    font-family: "Rozha One";
    font-size: 5vw;
    letter-spacing: 2rem;
    color: #fff;
    text-align: center;
}

.font-header {
    font-family: Rubik sans-serif;
    text-align: center;
}

.font-large {
    font-size: 3vw;
}

.font-medium {
    font-size: 1.5vw;
}

.text {
    /* padding: 1rem; */
    padding: 0.5rem;
    margin: 0;
    font-family: 'Roboto Mono';
}

.text-left {
    text-align: left;
    padding-right: 5vw;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
    padding-left: 5vw;
}

.icons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    width: 100%;
    justify-content: center;
}

.icons li {
    list-style: none;
    margin: 0.2rem;
    height: auto;
    width: 15%;
}

@media screen and (max-width: 500px) {
    BODY {
        font-size: 3vw;
    }

    .sm {
        display: block;
    }

    .lg {
        display: none;
    }

    .inner {
        padding-top: 1vh;
    }

    .item {
        /* background-color: darkslateblue; */
        width: calc((100% - 2px) / 2);
        /* height: calc((100% - 2px) / 3); */
        /* min-height: calc((100% - 2px) / 3); */
        height: calc((100% - 2px) / 6);
        max-height: calc((100% - 2px) / 6);
        display: flex;
        justify-content: center;
        align-items: start;
    }

    .column-2 {
        align-items: center !important;
    }

    .img {
        height: calc(100% - 1vw);
        margin: .5vw;
        width: auto;
    }

    .font-large {
        font-size: 6vw;
    }

    .font-medium {
        font-size: 3vw;
    }

    .text-left {
        text-align: center;
    }

    .text-right {
        text-align: center;
    }

    .hr-vertical {
        display: none;
    }

    .hr-horizontal {
        display: none;
    }

    h1 {
        font-size: 7.2vw;
        color: #fff;
    }

    h2 {
        font-size: 6vw;
    }

    /* h1 {
        font-size: 3rem;
    }
    h2 {
        font-size: 2rem;    
    } */
    h3 {
        /* font-size: 4vw; */
        font-size: 10vw;
        width: 100%;
        letter-spacing: 1.5rem;
        overflow-wrap: break-word;
    }
}

@media screen and (max-width: 970px) and (min-width: 500px) {

    h3 {
        font-size: 4vw;
        letter-spacing: 1.5rem;
    }
}