@import "https://fonts.googleapis.com/css2?family=Rozha+One&display=swap";
@import "https://fonts.googleapis.com/css2?family=Rozha+One&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&family=Rozha+One&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap";
BODY {
  color: #d4d4d4;
  background-color: #000;
  font-family: sans-serif;
  font-size: 1.05vw;
}

p {
  margin: 0;
  padding: 0;
}

.sm {
  display: none;
}

.container {
  background-color: #000;
  justify-content: center;
  align-items: center;
  width: 99vw;
  height: 97vh;
  margin: 0 auto;
  padding: 0;
  display: flex;
}

.outer {
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 90%;
  position: relative;
}

.inner {
  background-color: #282828;
  flex-flow: wrap;
  justify-content: space-around;
  align-items: stretch;
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
}

.item {
  width: calc(33.3333% - .666667px);
  height: calc(33.3333% - .666667px);
  max-height: calc(33.3333% - .666667px);
  display: flex;
}

.column-2 {
  flex: 2 0 calc(66.6667% - 1.33333px);
}

.column-3 {
  flex: 3 auto;
}

.left {
  justify-content: start;
}

.center {
  justify-content: center;
}

.right {
  justify-content: end;
}

.top {
  align-items: start;
}

.middle {
  align-items: center;
}

.bottom {
  align-items: end;
}

.img {
  width: auto;
  height: calc(100% - 1vw);
  margin: .5vw;
}

.borderTLH {
  background-color: #fff;
  width: 5%;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
}

.borderTLV {
  background-color: #fff;
  width: 2px;
  height: 10%;
  position: absolute;
  top: 0;
  left: 0;
}

.borderTRH {
  background-color: #fff;
  width: 5%;
  height: 2px;
  position: absolute;
  top: 0;
  right: 0;
}

.borderTRV {
  background-color: #fff;
  width: 2px;
  height: 10%;
  position: absolute;
  top: 0;
  right: 0;
}

.borderBRH {
  background-color: #fff;
  width: 5%;
  height: 2px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.borderBRV {
  background-color: #fff;
  width: 2px;
  height: 10%;
  position: absolute;
  bottom: 0;
  right: 0;
}

.borderBLH {
  background-color: #fff;
  width: 5%;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.borderBLV {
  background-color: #fff;
  width: 2px;
  height: 10%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.hr-vertical {
  background-image: url("data:image/svg+xml,%3csvg width='1px' height='20px' xmlns='http://www.w3.org/2000/svg'%3e%3cline x1='0.5' y1='5' x2='0.5' y2='15' stroke='white' stroke-width='1' stroke-dasharray='10%2c 10'/%3e%3c/svg%3e");
  background-position-y: center;
  background-repeat: repeat-y;
  width: 1px;
  min-width: 1px;
  max-width: 1px;
}

.hr-vertical-top {
  position: relative;
  top: 7.5%;
}

.hr-vertical-top-small {
  position: relative;
  top: 2.5% !important;
}

.hr-vertical-middle {
  visibility: hidden;
}

.hr-vertical-bottom {
  position: relative;
  top: -7.5%;
}

.hr-vertical-bottom-small {
  position: relative;
  top: -2.5% !important;
}

.hr-horizontal {
  background-image: url("data:image/svg+xml,%3csvg width='20px' height='1px' xmlns='http://www.w3.org/2000/svg'%3e%3cline x1='5' y1='0.5' x2='15' y2='0.5' stroke='white' stroke-width='1' stroke-dasharray='10%2c 10'/%3e%3c/svg%3e");
  background-position-x: center;
  background-repeat: repeat-x;
  width: 100%;
  min-width: 100%;
  height: 1px;
  min-height: 1px;
  max-height: 1px;
}

h1 {
  color: #fff;
  font-size: 3.15vw;
}

h2 {
  font-size: 2vw;
}

h3 {
  letter-spacing: 2rem;
  color: #fff;
  text-align: center;
  font-family: Rozha One;
  font-size: 5vw;
}

.font-header {
  text-align: center;
  font-family: Rubik sans-serif;
}

.font-large {
  font-size: 3vw;
}

.font-medium {
  font-size: 1.5vw;
}

.text {
  margin: 0;
  padding: .5rem;
  font-family: Roboto Mono;
}

.text-left {
  text-align: left;
  padding-right: 5vw;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
  padding-left: 5vw;
}

.icons {
  flex-flow: wrap;
  justify-content: center;
  width: 100%;
  padding: 0;
  display: flex;
}

.icons li {
  width: 15%;
  height: auto;
  margin: .2rem;
  list-style: none;
}

@media screen and (width <= 500px) {
  BODY {
    font-size: 3vw;
  }

  .sm {
    display: block;
  }

  .lg {
    display: none;
  }

  .inner {
    padding-top: 1vh;
  }

  .item {
    justify-content: center;
    align-items: start;
    width: calc(50% - 1px);
    height: calc(16.6667% - .333333px);
    max-height: calc(16.6667% - .333333px);
    display: flex;
  }

  .column-2 {
    align-items: center !important;
  }

  .img {
    width: auto;
    height: calc(100% - 1vw);
    margin: .5vw;
  }

  .font-large {
    font-size: 6vw;
  }

  .font-medium {
    font-size: 3vw;
  }

  .text-left, .text-right {
    text-align: center;
  }

  .hr-vertical, .hr-horizontal {
    display: none;
  }

  h1 {
    color: #fff;
    font-size: 7.2vw;
  }

  h2 {
    font-size: 6vw;
  }

  h3 {
    letter-spacing: 1.5rem;
    overflow-wrap: break-word;
    width: 100%;
    font-size: 10vw;
  }
}

@media screen and (width <= 970px) and (width >= 500px) {
  h3 {
    letter-spacing: 1.5rem;
    font-size: 4vw;
  }
}
/*# sourceMappingURL=index.aca9df38.css.map */
